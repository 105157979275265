<template>
  <ClientOnly>
    <button
      class="cursor-pointer"
      :aria-label="
        isInWishlist
          ? $t('product.removeFromWishlist')
          : $t('product.addToWishlist')
      "
      v-bind="$attrs"
      @click="toggleWishlist"
    >
      <IconHeart
        :filled="isInWishlist"
        class="size-full"
        :class="{
          'text-red-500': isInWishlist,
        }"
      />
    </button>
  </ClientOnly>
</template>

<script setup lang="ts">
import type { Schemas } from '#shopware'

const props = defineProps<{
  product: Schemas['Product']
}>()

const { removeFromWishlist, addToWishlist, isInWishlist } = useProductWishlist(
  props.product.id
)

const toggleWishlist = async () => {
  if (isInWishlist.value) {
    await removeFromWishlist()
  } else {
    await addToWishlist()
  }
}
</script>
